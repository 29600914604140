@media print {
    @page {
        size: portrait;
        margin: 0.5cm;
    }
    
    body * {
        visibility: hidden;
        /* font-family: "Helvetica", "Verdana", "Century Gothic", sans-serif; */
        overflow-wrap: break-word;
        /* font-size: 0.95em; */
        line-height: initial;
    }

    body{
        background-color: #ffffff !important;
        margin: 0;
        padding: 0;
    }

    .section-to-print, .section-to-print * {
        visibility: visible;
    }

    .section-to-print {
        background-color: #ffffff !important;
        width: 98%;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        -webkit-print-color-adjust: exact; 
        /* width: fit-content !important;
        align-items: center !important; */
    }
    /* #print-layout-container{
        padding-top:0 !important
    } */

    .makeStyles-itineryh1-4{
        padding:9px !important
      }

      #print-style{
        page-break-after: always;
        padding: 10px !important;
    margin-top: 10px !important;
      }
    /* div, .MuiGrid-root:not(td), .MuiTableCell-sizeSmal:not(td), .MuiTableCell-root:not(td) {
        margin: 0 !important;
        padding: 0 !important;
        border: 0!important;
    }  */

    .section-not-to-print, .MuiPaper-root {
        display: none !important;
    }

    div[class*="makeStyles-card-"] {
        box-shadow: none !important;
    }

   /* .MuiTableContainer-root{
        justify-content: center;
        align-items: center;
        display: flex;
    } */

/* 
    .MuiGrid-root{
        background-color: #ffffff !important;
    } */

    .makeStyles-mainPanel-2{
        float: none !important;
        width: 98% !important;
    }

    .print-view-style{
        width: 100vw !important;
        /* height: 100vh !important;
        overflow: auto; */
        page-break-after: always;
        
    }
}