/* .box {
    margin-top: 131px;
    margin-right: 102px;
    margin-left: 125px;
 }
 
 .bus-and-traveller-details{
     margin-left: 16px;
     
 }
 
 .bus-details{
     height: 58px;
 }
 
 
 
 .passenger{
     padding-top:68px;
 }
 
 
 .payment-details{
     margin-top: 20px;
 }
 
 .body-text{
     font-family: Helvetica Neue;
     font-style: normal;
     font-weight: 100;
     font-size: 14px;
     line-height: 17px;
     letter-spacing: 0.63px;
     color: #000000;
 
 }
 
 .small-text{
     font-family: Helvetica Neue;
     font-style: normal;
     font-weight: 100;
     font-size: 10px;
     line-height: 17px;
     letter-spacing: 0.63px;
     color: #000000;
 }
 
 
 .title-box{
     position: absolute;
     width: 103px;
     height: 53px;
     left: 281px;
     top: 51px;
     background: #EC6161;
 }
 
 
 .cash-balance{
     background-color:  #EC6161 !important;
     color: white !important;
     border-top-left-radius: .25rem;
     border-top-right-radius: .25rem;
     padding: 10px 15px;
 }
 
 .note{
     margin-top: 20px;
     margin-left: 6px;
 } */

 .heading-text{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 1.23667px;
    
    color: #f76b6b;

}

.passenger-field-label{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.971667px;

    color: #5E5E5E;
}

.title-text{

    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 1.23667px;

    color: #007A92;
    margin-top: 20px;

}

 .passenger-price-label{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 1.33333px;
    
    color: #7C5959;
 }


 .passenger-price{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 1.33px;

    color: #f76b6b;
 }


 .passenger-selected-count{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1.08px;
    
    color: #000000;
 }

 .passenger-selected{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.63px;
    
    color: #000000;
 }

 .passenger-bus-details-box{
    border: 1px solid #00000014;
    border-radius: 7px;
    padding: 5px;
    margin-top: 5px;
 }

 .heading-text {
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    letter-spacing: 1.23667px;
    
    color: #f76b6b;
 }

 .passenger-info-title {
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.671667px;
    
    color: #5E5E5E;
    padding-top: 5px;
 }

 .passenger-textfield{
    border: 1px solid #979797;
    border-radius: 6px;
    min-width: 85%;
 }

 .passenger-field{
    /* border: 1px solid #979797; */
    /* border-radius: 6px; */
    background: transparent;

    padding-top: 12px;
    padding-right: 14px;
    padding-bottom: 12px;
    padding-left: 14px;
    font-size: 14px;
    font-weight: 200;
    /* color: currentColor; */
    width: 90%;
    outline: 0;
    border-width: 0 0 2px;
    border-color: #666666
  
 }
/* 
 .passenger-field:focus{
 outline: -webkit-focus-ring-color auto 1px;
 } */
 

 .gst-details-label{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    letter-spacing: 0.46px;
    
    color: #5E5E5E;
 }

 .payment-info-title{
    font-size: 1rem;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
 }

 .payment-cash-balance-title{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    background: #007A92;
    width: 150px;
    color: white;
    padding: 10px;
    border-top-right-radius: 10px;
 }
 
 .bus-and-traveller-details{
     margin-top: 50px;
 }

 .text-danger {
    color: #f76b6b !important;
}

.passenger-note-text{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.46px;

    color: #5E5E5E;
}

.passenger-details-review-button{
    /* font-family: Helvetica Neue;
    font-style: normal; */
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.03333px;

    color: #FFFFFF;
    background: #f76b6b;
    border-radius: 5px;
}

.add-remove-button {
    color: #0C0C0C !important;
    background: #F89605 !important;
    border-radius: 8px !important;
    cursor: pointer;
    min-width: 24px !important;
    width: 20px;
    margin: 2px !important;
    padding: 0 !important;
}

.add-remove-button-disabled {
    color: #0C0C0C !important;
    background: #F89605 !important;
    border-radius: 8px !important;
   
    min-width: 24px !important;
    width: 20px;
    margin: 2px !important;
    padding: 0 !important;
}
 