/* 

.travells{
	font-weight: 900;
	font-size: 1.2rem;

}
.content-wrapper {
	padding-left: 1cm;
	padding-right: 1cm;
	position: relative;
	
}

p {
	margin: 0px !important;
}

table {
	background: white;
	border-collapse: collapse;
	width: 100%;
}

hr {
	margin: 0px;
}

th {
	color: white;
}

td,
th {
	text-align: center;
	border: 1px solid #000;
}

table td p {
	font-size: 12px;
}

thead tr {
	height: 1.5em;
}

ul li {
	margin: 0px;
}

section {
	clear: both;
}

.w2 {
	width: 48%;
}

.left {
	float: left;
}

.right {
	float: right;
}

.logo {
	width: 20%;
	float: left;
}

.label {
	font-size: 0.8em;
	height: 5em;
	border-radius: 14px;
	width: 15em;
	color: white;
	text-align: center;
	margin-bottom: -1.5em;
	padding: 1.5%;
}

.airline-logo {
	width: 70%;
}

.plane-icon {
	width: 16%;
	float: left;
}

.align-text-left {
	padding-left: 0.5em !important;
	text-align: left;
}

.redline {
	background: #007A92;
	width: 100%;
	height: 30px;
	max-height: 30px !important;
	padding-top: 1px;
	margin-bottom: 5px;
}

.redline p {
	font-size: 8.85pt;
	text-align: center;
	color: white;
}

.booking-info {
	float: right;
	width: 70%;
}

.booking-info table {
	border-collapse: collapse !important;
}

.booking-info td,
th,
tr {
	border: none;
	text-align: center;
	padding: 0px;
}

.booking-info p {
	margin: 0px !important;
	text-align: right;
	font-size: 0.8em;
}

.itinerary-section {
	position: relative;
	    margin-top: 60px;

}

.header-section {
	height: 8em;
	margin: 1%;
	margin-bottom: 6rem;
}

.passenger-addon-detail p {
	font-size: 6px;
}

.fare-section {
	height: 14em;
	margin-bottom: 3%;
}

.fare-brakeup table {
	height: 11.5em;
}

.fare-brakeup thead p {
	font-size: 0.5em;
}

.footer-section {}

.footer-section li {
	font-size: 7pt;
}

.footer-section h1 {
	width: 100%;
	text-align: center;
	font-size: 10pt;
}

.red {
	background: #007A92;
}

.red-text {
	color: #007A92;
}

.bold {
	font-weight: 700;
}

.gray {
	background: #4D4D4D;
}

.city {
	text-transform: uppercase;
	font-size: 1em;
	width: 15%;
	margin-top: 3%;
	margin-left: 3%;
	float: left;
	text-align: center;
}

.backrow-businfo {
	height: 9em;
	border: 2pt solid black;
	border-radius: 0.8em;
	padding-left: 3%;
	border-left: 1px solid;
	margin-top: 2%;
	text-align: center;
	width: 100%;
}

.bus-info-right-column {
	padding-left: 3%;
	border-left: 1px solid;
	float: right;
	margin-top: 2%;
	text-align: center;
	margin-right: 3em;
	width: 55%;
}

.bus-info-coloumn {
	width: 30%;
	float: left;
	margin-left: 0%;
	font-size: 0.8em;
}

.additional-bus-info {
	text-align: center;
	font-size: 8pt;
	text-transform: uppercase;
	float: right;
	margin-right: 3%;
	margin-top: 2%;
}

.bus-info-duration {
	font-size: 10px;
	border-radius: 8px;
	width: 10em;
	color: white;
	width: 168px;
	margin-top: 5%;
	margin-left: 11%;
	height: 55px;
	padding-top: 6px;
}

.pnr-section {
	margin-top: -5px;
	padding: 50em;
	z-index: 800;
	float: left;
	height: auto;
	text-align: center;
	padding: 1em;
	width: 9em;
	border: 2pt solid black;
	border-radius: 1em;
	background: white;
}

.agency-contact {
	width: 80%;
	margin-bottom: 2%;
}

.agency-contact>tr,
td {
	border-top: 0px;
}

.bus-contact {
	width: 80%;
}

@page {
	margin: 0px;
	size: A4 portrait;
	orphans: 2;
	window: 2;
}

@media print {
	body {
		padding: 0px;
		background: white;
		
	}
	.container {
		border: 1px solid black;
		width: 100% !important;
		height: auto !important;
		margin: 0px;
		background: white;
	}
	.arrival-header {
		padding: 1px;
	}
	.logo img {
		float: left;
		width: 50%;
	}
	.city {
		text-transform: uppercase;
		font-size: 0.8em;
		width: 15%;
		margin-top: 3%;
		float: left;
		text-align: center;
	}
	.bus-info-coloumn {
		width: 26%;
		font-size: 0.8em;
		float: left;
		margin-left: 6%;
	}
	.bus-info-right-column {
		padding-left: 3%;
		border-left: 1px solid;
		float: right;
		margin-top: 2%;
		text-align: center;
		margin-right: 3em;
		width: 45%;
	}
	.backrow-businfo {
		height: 7em;
		border: 2pt solid black;
		border-radius: 0.8em;
	}
	.bus-info-duration {
		font-size: 11px;
	border-radius: 8px;
	width: 12em;
	color: white;
	width: 168px;
	margin-top: 5%;
	margin-left: 11%;
	height: 38px;
	padding-top: 6px;
	}
} */

.s3-padding {padding: 2rem 1rem;}

/* body {color: #55595c !important;} */

.red-bg {background-color: #c22229;}

.text-light-red {color: #fc8085;}
.text-red {color: #c22229 !important;}

.pt {padding-top: 0px !important;}
.pb {padding-bottom: 0px !important;}

.text-center {
    text-align: center!important;
}

.pt-5, .py-5 {
    padding-top: 3rem!important;
}

.float-right {
    float: right!important;
}

.bg-light {
    background-color: #f8f9fa!important;
}

.text-white {
    color: #fff!important;
}

.small, small {
    font-size: 80%;
    font-weight: 400;
}

.p-3 {
    padding: 1rem!important;
}

/*! CSS Used from: https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css */

h4,h5{margin-top:0;margin-bottom:.5rem;}
b{font-weight:bold;}
img{vertical-align:middle;border-style:none;}
h4,h5{margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h4{font-size:1.5rem;}
h5{font-size:1.25rem;}
.small{font-size:80%;font-weight:400;}
.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
.row{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
.col-md-4,.col-sm-6{position:relative;width:100%;padding-right:15px;padding-left:15px;}
@media (min-width:576px){
.col-sm-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
}
@media (min-width:768px){
.col-md-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
}
.p-3{padding:1rem!important;}
.py-5{padding-top:3rem!important;}
.py-5{padding-bottom:3rem!important;}
.text-right{text-align:right!important;}
.text-center{text-align:center!important;}
.text-white{color:#fff!important;}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
img{page-break-inside:avoid;}
.container{min-width:992px!important;}
}
/*! CSS Used from: Embedded */
.red-bg{background-color:#c22229;}
.text-light-red{color:#fc8085;}
.pt{padding-top:0px!important;}
.pb{padding-bottom:0px!important;}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table  .thead-dark td {
    color: #fff !important;
    background-color: #343a40;
    border-color: #454d55;
}


@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 
   .Header{
	   font-size: 20px;
   }
   .pnrinfo{
	font-size: 12px;
   }
   .depAndDest{
	   font-size: 13px;
   }
   .bus-detail-div{
	   padding-left: 10px;
	   
   }
   .bus-terms-condition{
	padding-left: 7px;
    padding-right: 5px;
   }
   .tableDiv{
	overflow-x:auto;
   }
  
}

@media only screen 
and (min-device-width: 0px) 
and (max-device-width: 325px) 
and (-webkit-min-device-pixel-ratio: 2) { 
	.print-view-style{
		width: 100vw;
		overflow-x: scroll;
	}
	.tableDiv{
		overflow-x:auto;
	   }
}

@media all and (max-width:320px) {
	.print-view-style{
		width: 100vw;
		overflow-x: scroll;
	}
	.tableDiv{
		overflow-x:auto;
	   }
}

@media all and (max-width: 500px) {
	.top-section-wrapper {
		flex-direction: column;
		margin-right: 0 !important;
		margin-left: 0 !important;
		justify-content: flex-start !important;
		align-items: center !important;
	}
	.brand-logo-wrapper {
		max-width: 100% !important;
		flex: unset !important;
		display: flex;
		justify-content: center !important;
	}
	.service-text-title-wrapper {
		max-width: 100% !important;
		flex: 1 !important;
	}
	.ticket-info-outer-wrapper {
		flex: 1 !important;
		max-width: 100% !important;
	}
	.ticket-info-text-wrapper {
		width: 100% !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}
	.ticket-info-text-wrapper h5 {
		width: 100%;
		text-align: center !important;
	}
}
