.header-row{
background: black !important;
color: white !important;
padding: 5px;
font-weight: bold;
}

.sub-header-row{
background: #ccc;
padding: 2px;
align-items: center;
/* display: flex; */
font-size: 12px;
}

.small-font{
font-size: 10px;
}

.xtrasmall-font{
font-size: 8px;
}

.data {
border: 2px solid black;
margin-bottom: 10px !important;
padding: 10px;
font-size: 12px;
}

.flight-details-hrizontal-separator{
border-top: 2px solid;
border-bottom: 2px solid;
}

.flight-details-vertical-separator{
border-right: 2px solid;
}

.editmarkup{
/* display: flex; */
align-items: center;
justify-content: left;
}

.flight-icon{
padding-left: 12px;
}

@media print {
  /* div, .MuiGrid-root:not(td), .MuiTableCell-sizeSmal:not(td), .MuiTableCell-root:not(td) {
      margin: 0 !important;
      padding: 0 !important;
      border: 0!important;
  }  */
  
  #sidebar{
    display: none !important;
  }

  #mainContainer{
    float: none !important;
  }
}

/* css for tabs */

.icon-flight_icon{
  font-size: 40px;
}
.icon-money_transfer_icon{
  font-size: 40px;
}
.icon-recharge_icon{
  font-size: 40px;
}
.icon-hotel_icon{
  font-size: 40px;
}
.icon-bus_icon{
  font-size: 40px;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .icon-flight_icon{
      font-size: 20px;
      padding-left: 0px;
    }
    .icon-money_transfer_icon{
      font-size: 20px;
     
    }
    .icon-recharge_icon{
      font-size: 20px;
     
    }
    .icon-hotel_icon{
      font-size: 20px;
      
    }
    .icon-bus_icon{
      font-size: 20px;
      
    }
    
    
  
}