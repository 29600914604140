.maincontent {
  display: flex;
  flex-direction: column;
}

.maincontent-background {
  display: flex;
  background: var(--search-panel-background-color);
  margin: 5% 8%;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  padding: 20px;
}

/* .buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  border: 2px solid blue;
} */

.button {
  display: flex;
  background: var(--primary-background-color);
  flex-direction: row;
  height: 100px;
  margin: 0 5px;
  width: 160px;
  height: 70px;
  margin-top: -50px;
  align-items: center;
  justify-content: space-around;
}

.search-wrapper {
  /* display: flex;
  flex-direction: column; */
  align-items: center;
  width: 100%;
  justify-content: center;
}

.mainicon {
  width: 40px;
  height: 40px;
  color: var(--secondary-text-color);
}

.button p {
  font-size: 13px;
  width: 80px;
  text-align: center;
  color: var(--secondary-text-color);
}

.button:active,
.button:hover {
  background: var(--secondary-background-color);
}
.button:active p,
.button:hover p {
  color: var(--ternary-text-color);
}
.button:active .mainicon,
.button:hover .mainicon {
  color: var(--ternary-text-color);
}

.first-line {
  display: flex;
  margin-top: 30px;
}

/* .search-wrapper * {
  color: blue !important;
  margin: 0 100px !important; 
} */

.search-wrapper * {
  outline: none !important;
  border-width: 0 0 2px;
  border-color: #666666;
  width: 96%;
  background: transparent;
}

@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
  .search-wrapper * {
    outline: none !important;
    border-width: 0 0 2px;
    border-color: #666666;
    width: 0vh;
  }
}

.first-line > * {
  border: 0 !important;
}

.date-content > * {
  outline: none !important;
  border: 0 !important;
}

.date-content > * ::after {
  outline: none !important;
  border: 0 !important;
}
.date-content > * ::before {
  outline: none !important;
  border: 0 !important;
}

.date-content > * :hover {
  outline: none !important;
  border: 0 !important;
}

.date-content > * :hover {
  outline: none !important;
  border: 0 !important;
}

.from-to-selection-content > * :hover {
  outline: none !important;
  border: 0 !important;
}

.from-to-selection-content > * :focus {
  outline: none !important;
  border: 0 !important;
  background-color: transparent !important;
}

.from-to-selection-content > * ::after {
  outline: none !important;
  border: 0 !important;
  background-color: transparent !important;
}
.from-to-selection-content > * ::before {
  outline: none !important;
  border: 0 !important;
  background-color: transparent !important;
}

.from-to-selection {
  display: flex;
  background: var(--primary-background-color);
  width: 90%;
  padding: 0 2%;
  height: 60px;
}

.from-to-selection-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-direction: row;
}

.date-content {
  padding: 0 !important;
}

.iconbg {
  display: flex;
  height: 60px;
  width: 50px;
  background: var(--primary-background-color);
  align-items: center;
  justify-content: center;
}

.switchicon {
  width: 25px;
  height: 25px;
  align-self: center;
  color: var(--quaternary-text-color);
}

.from-to-text {
  color: var(--secondary-text-color);
  font-size: 0.8rem;
  justify-self: flex-start;
}

.calendaricon {
  width: 25px;
  height: 25px;
  align-self: center;
  color: var(--secondary-text-color);
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}

.date p {
  font-size: 1rem;
  color: var(--primary-text-color);
  margin: 0;
}

.combo-box-demo {
  display: flex;
  justify-content: space-around;
  align-content: center;
  padding-top: 10;
  width: 300px;
  height: 100%;
}

.autocomplete {
  padding: 0px 10px;
  /* padding-bottom: -5px; */
}

.second-line {
  margin: 0;
  margin-top: 40px;
}

.dropdownicon {
  width: 35px;
  height: 35px;
  align-self: center;
  color: var(--secondary-text-color);
}

.third-line {
  display: flex;
  width: fit-content;
  justify-content: center;
  padding: 0 35px;
  margin-top: 40px;
  background: var(--ternary-background-color);
  color: var(--ternary-text-color);
  font-weight: bold;
  padding: 10px 15px;
  cursor: pointer;
}

.third-line p {
  margin: 0;
  font-size: 1.1rem;
}

.maincontent-background.dark {
  margin: 0;
  margin-bottom: -20px;
  margin-top: 30px;
  background: var(--search-panel-background-color);
  width: 100%;
  /* justify-self: baseline; */
}

.other-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.other-wrapper p {
  color: var(--ternary-text-color);
}

.other-wrapper-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.other-icon-bg {
  display: flex;
  width: 120px;
  height: 120px;
  background: #aaa;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.other-icon {
  width: 50px;
  height: 50px;
  color: rgb(165, 159, 159);
  padding: 20px;
}

.other-wrapper-options p {
  font-size: 0.7rem;
}

.button_mobile {
  width: 70px;
}

.inputfield {
  text-decoration: none;
  border: none;
  font-size: 1rem;
  outline: none;
}

/* .inputfield: */

.button_mobile p {
  display: flex;
  color: var(--ternary-text-color);
  justify-content: center;
}

.mainicon_mobile {
  width: 60px;
  height: 80px;
  background: var(--ternary-text-color);
  padding: 10px 10px;
  color: var(--secondary-text-color);
}

.mainicon_mobile:active,
.mainicon_mobile:hover {
  background: var(--secondary-text-color);
  color: var(--ternary-text-color);
}

@media (max-width: 800px) {
  .search-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .first-line {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .from-to-selection {
    margin: 10px 0;
  }
  .iconbg {
    display: none;
  }
  .second-line {
    display: flex;
    flex-direction: column;
    /* margin: 10px 0; */
    width: 100%;
    align-items: center;
    /* margin: 0; */
  }

  .button {
    display: none;
  }
  .buttons {
    justify-content: space-evenly;
  }
  .button_mobile p {
    text-align: center;
    font-size: 0.7rem;
  }
  .from-to-selection {
    display: flex;
    justify-content: center;
  }
  .third-line {
    display: flex;
    align-self: center;
    margin-top: 10px;
    margin-left: 0;
  }
  .from-to-selection-content {
    justify-content: space-between;
    padding: 0 30px;
  }
  .other-wrapper-options {
    align-self: center;
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .other-icon-bg {
    margin: 10px;
  }

  .MuiTab-root {
    min-width: 50px;
    border: 3px solid var(--primary-text-color);
  }
}

@media (min-width: 801px) {
  .button_mobile {
    display: none;
  }
  .first-line {
    margin-top: 70px;
  }
  .second-line {
    max-width: 35%;
  }
}
/* 
.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
   transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; 
   border-bottom: 0 !important; 
 
  pointer-events: none;
  
} */

@media (max-width: 768px) {
  .MuiTab-root {
    min-width: 40px !important;
  }
  .MuiTabs-root {
    flex: 1 !important;
  }

  .OaIconTabBar-tabStyle-252 {
    margin-right: 0 !important;
    margin: 0 5px !important;
  }

  .OaIconTabBar-activeTabStyle-253 {
    margin-right: 0 !important;
    margin: 0 5px !important;
  }

  .OaIconTabBar-iconLabelWrapper-250 {
    margin: 0 !important;
  }

  .OaIconTabBar-iconLabelWrapperActive-251 {
    margin: 0 !important;
  }
}

/* .MuiOutlinedInput-notchedOutline {
  border: none !important;
} */

.date-selection > .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-text-color);
}

.date .MuiFormControl-fullWidth {
  width: 50%;
}

.date-content {
  display: flex;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .result-item-select-button-r0 {
    width: 98%;
  }
}

.MuiFilledInput-root {
  filter: drop-shadow(0px 3px 34px rgba(179, 179, 179, 0.161));
}

.glqWOv {
  margin-top: -64px;
}
.kwUGKV:disabled {
  margin-top: -64px;
}
