#voucher {
  border-collapse: collapse;
  width: 100%;
}

#voucher td,
#voucher th {
  border: 2px solid #ddd;
  padding: 8px;
  font-size: 12px;
  
}

#voucher tr {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: rgb(33, 37, 41);
  color: #ffffff;
  font-size: 12px;

}
#voucher td {
  background-color: #ffffff;
  color: #636060;
  
}
@media print {
    .section-to-print-mobile{
        width: 100vw;
    }
    
    form{
        display: none;
    }

}
.section-to-print-mobile, .section-to-print-mobile * {
    visibility: visible;
}
.section-to-print-mobile {
    background-color: #ffffff;
    /* width: 50%; */
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    -webkit-print-color-adjust: exact; 
}