.selectedText {
  background: #f6f6f6;
  mix-blend-mode: normal;
  opacity: 0.9;
  font-weight: 500;
  font-size: 16px;

  letter-spacing: 1.5px;
  color: #000000;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.selectedButton{
    background: #E2BE69;
    mix-blend-mode: normal;
    opacity: 0.9;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1.06px;
    padding: 12px;
    margin: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectedButton :hover{
    background: #E2BE69;
    cursor: pointer;
}
