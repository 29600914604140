.table-detail .thead-dark th {
    color: #0C0C0C;
    background-color: #F89605;
    border-color: #F89605;
}
.table-detail  .thead-dark td {
    color:#fcc03d !important;
    background-color: #444444;
    border-color: #444444;
}
.text-red {color: #c22229 !important;}
.ppr {
    margin: 1rem 1rem;
    text-align: center;
}

.comp {
    /* padding: 1rem 2rem;*/
    padding: 1px;
    align-content: center;
}

hr.small {
    padding: 0;
    margin: 0;
    border: 0;
    border: 0;
    height: 1px;
}

hr.hr-bg-1 {
    background-color: var(--primary-background-color);
}

/*white-bg*/
hr.hr-bg-2 {
    background-color: gray;
}

/*gray-bg*/
hr.hr-bg-3 {
    background-color: blue;
}

/*blue-bg color-bg*/


/* .m-5 {
    margin: 5px !important;
}
 */
.mt-5 {
    margin-top: 5px !important;
}

/* .mr-5 {
    margin-right: 5px !important;
} */

.mb-5 {
    margin-bottom: 5px !important;
}

.ml-5 {
    margin-left: 5px !important;
}


.mt-10 {
    margin-top: 10px !important;
}


.mb-10 {
    margin-bottom: 10px !important;
}

/* .ml-10 {
    margin-left: 10px !important;
} */

@media screen and (max-width:960px) {
    .ppr {
        margin: 2rem 0;
        position: relative;
        justify-content: space-around;
    }

    .price {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .st-lft {
        padding-top: 0%;
        margin-bottom: 1rem;
    }

    .trvlr {
        padding-left: 1.5rem;
        padding-bottom: 1rem;
    }

}

@media screen and (max-width:500px) {
    .trvlr {
        padding-left: 0;
        padding-bottom: 1rem;
    }
}
