.destination-box {
  /*border: 1px solid #b1b1b1;*/
  border-radius: 10px;
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
  min-height: 333px;
  background: #fff;
  box-shadow: 0px 0px 5px -2px #757575;
  cursor: pointer;
  width: 100%;
}
.destination-box:hover {
  color: #000;
  text-decoration: none;
}
.destination-main-text {
  padding: 15px;
}

.destination-main-text span {
  display: inline-block;
  margin-top: 7px;
  color: #000;
  font-size: 14px;
  /* margin-left: 10px; */
  font-weight: 500;
}

.destination-main-text p {
  font-size: 16px;
  color: #000;
  line-height: 1.3;
  font-weight: 500;
}

.destination-box img {
  border-radius: 7px 7px 0px 0px;
  height: 220.91px;
}

.destination-box:hover img {
  /*filter: hue-rotate(45deg);*/
  /*filter: brightness(0.5);*/
  filter: grayscale(1);
}

.destination-box > p {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
}

.destination-box:hover > p {
  visibility: visible;
  opacity: 1;
}

.destination-box:hover strike,
.destination-box strike {
  color: #757575;
  font-size: 14px;
}

.destion-booking-heading {
  font-size: 26px;
  line-height: 20px !important;
  font-weight: 500 !important;
  padding-left: 15px;
  margin-bottom: 20px;
  color: #000;
  padding-top: 20px;
}

.destination-booking-text > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d4d4d4;
  padding: 5px 0px;
  font-size: 14px;
}

.destination-booking-text > div:last-child {
  border: none;
}

.destination-booking-box {
  border-radius: 10px;
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
  min-height: 333px;
  background: #fff;
  box-shadow: 0px 0px 5px -2px #757575;
}

.destination-highlights > div {
  border-radius: 10px;
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
  min-height: 333px;
  background: #fff;
  box-shadow: 0px 0px 5px -2px #757575;
  padding: 20px;
  width: 100%;
}

.destination-booking-box > div {
  padding: 20px;
}

a.req-btn {
  background: #4188bf;
  border: none;
  color: #fff;
  padding: 6px 11px;
  border-radius: 30px;
  font-size: 13px;
  border: 1px solid #ccc6c6;
  margin-top: 5px;
}

a.change-btn {
  background: #b7b7b7;
  border: none;
  color: #fff;
  padding: 6px 11px;
  border-radius: 30px;
  font-size: 13px;
  border: 1px solid #ccc6c6;
  margin-top: 5px;
}

a:hover {
  text-decoration: none;
}

.destination-booking-text > div > p {
  font-weight: 400;
}

.destination-booking-text > div > span {
  font-weight: 600;
}

.destination-booking-outer > .nav-tabs .nav-item.show .nav-link,
.destination-booking-outer > .nav-tabs .nav-link.active {
  background: #4188bf !important;
  color: #fff !important;
  padding: 10px 15px;
  border-radius: 10px 10px 0px 0px;
  font-size: 14px;
}

.destination-booking-outer > .nav-tabs .nav-link {
  background: #f7f7f7;
  border: 1px solid #d6d6d6;
  display: block;
  text-align: center;
  font-size: 14px;
  padding: 10px 15px;
  color: #235481;
  border-radius: 10px 10px 0px 0px;
}

.destination-booking-outer > .h3:first-child {
  padding-top: 0px;
}

.destination-booking-outer > .h3 {
  color: #000 !important;
  padding-bottom: 15px;
  font-size: 17px !important;
  margin-bottom: 0px;
}

.destination-booking-outer > .h3 ~ p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 15px !important;
}

.destination-booking-outer > .destination-booking-box img {
  border-radius: 10px 10px 0px 0px;
}

.destination-booking-outer > .nav-tabs .nav-link:focus,
.destination-booking-outer > .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  background: #658faf78;
  color: #fff;
  transition: all 0.3s;
}

.destination-booking-outer > table th,
.destination-booking-outer > table td {
  border: 1px solid #cacaca !important;
  padding: 7px !important;
  color: #636060;
  border: 1px solid;
  padding: 7px;
  font-size: 12px;
}

.destination-booking-outer > table thead {
  background: #efefef;
}

.destination-booking-outer > label,
.destination-booking-outer > form p {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.42857;
  color: #666666;
  margin-bottom: 5px;
}

.destination-booking-outer > input,
.destination-booking-outer > textarea {
  font-size: 14px;
  line-height: 1.42857;
  color: #666666;
  background-color: white;
  border: 1px solid #cccccc;
  display: block;
  width: 100%;
  border-color: #d3dbe4;
  box-shadow: none;
  border-radius: 4px;
  height: 35px;
  padding: 10px;
  margin-bottom: 15px;
}

.destination-booking-outer > textarea {
  height: auto;
}

.destination-booking-outer > select {
  font-size: 14px;
  line-height: 1.42857;
  color: #666666;
  background-color: white;
  border: 1px solid #cccccc;
  display: block;
  width: 100%;
  border-color: #d3dbe4;
  box-shadow: none;
  border-radius: 4px;
  height: 35px;
  padding: 6px 10px;
  margin-bottom: 15px;
}

.destination-booking-outer > .radio-btn ~ label {
  display: inline-block;
  vertical-align: text-top;
  margin-right: 15px;
}

.destination-booking-outer .radio-btn {
  height: 15px;
  width: 15px;
  display: inline-block;
}

.destination-booking-outer > input[type="checkbox"] ~ label {
  vertical-align: top;
}
.destination-booking-outer > input[type="checkbox"] {
  margin: 1px;
  margin-bottom: 23px;
  width: 15px;
  height: 15px;
}

.destination-booking-outer > form p {
  margin-bottom: 7px !important;
}

.destination-booking-outer > form button {
  padding: 10px 19px;
  color: #fff;
  background-color: #da393e;
  border: 1px solid #ea2330;
  font-weight: bold;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  line-height: 1.42857;
  white-space: nowrap;
  border-radius: 3px;
}

.destination-booking-outer > input:focus,
.destination-booking-outer > select:focus,
.destination-booking-outer > textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
    0 0 8px rgb(102 175 233 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
