.ReactTable .rt-tbody .rt-td {
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    /* border-top: 1px solid #cccccc; */
    /* height: 100%; */
    height: auto !important;
    margin: 0 !important;
}

.bottom-border{
    /* border-bottom: 1px solid #cccccc; */
}