.page-content-box {
  margin-top: 35px;
  margin-bottom: 35px;
  width: 100%;
  background: #ffffff;
  /* box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.21); */
  /* border-radius: 10px; */
  padding: 20px;
}

.section-title {
  /* font-family: Helvetica Neue; */
  /* font-style: normal; */
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1.33333px;
  color: #5e5e5e;
  margin-bottom: 15px;
}

.fare-breakup-text {
  /* font-family: Helvetica Neue; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1.5px;
  color: #5e5e5e;
  margin-bottom: 15px;
}

.departure-fare-text {
  /* font-family: Roboto; */
  /* font-style: normal; */
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #5e5e5e;
  margin-bottom: 15px;
}

.fare-text-left {
  /* font-family: Roboto;
    font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.1px;
  color: #5e5e5e;
}

.fare-text-right {
  /* font-family: Roboto;
    font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #5e5e5e;
}

.fare-text-right {
  /* font-family: Roboto;
    font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #5e5e5e;
}

.line-separator-review {
  width: 100%;
  height: 1px;
  border-bottom: 0.2px solid #bdb9b9;
  margin-bottom: 10px;
  margin-top: 10px;
}

.total-fare {
  /* font-family: Roboto;
    font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #007a92;
}

.grand-total-fare {
  /* font-family: Roboto;
    font-style: normal; */
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #007a92;
}

.passenger-info-label {
  /* font-family: Helvetica Neue; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  color: #007a92;
}

.passenger-info-text {
  /* font-family: Helvetica Neue; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.16667px;
  color: #5e5e5e;
}

@media all and (max-width: 500px) {
  .passenger-info-label {
    text-align: left;
  }
  .passenger-info-text {
    text-align: right;
    overflow-x: hidden;
  }
}

.sub-title-text {
  /* font-family: Helvetica Neue; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.dest-txt {
  /* font-family: Helvetica Neue; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.886667px;
  color: #000000;
  padding: 5px;
}

.date-txt {
  /* font-family: Helvetica Neue; */
  /* font-style: normal; */
  font-size: 12px;
  color: #000000;
}
