.oatimer {
  left: 93%;
  top: 65px;
  height: 100%;
  position: absolute;
  background-size: cover;
  display: flex;
  align-items: center;
  border: 1px solid;
  height: 20px;
  padding: 5px;
  border-radius: 5px;
}

@media screen and (max-width: 1300px) {
  .oatimer {
    /* left: 680px !important; */
    left: 90%;
  }
}
@media screen and (max-width: 800px) {
  .oatimer {
    left: 85% !important;
    /* left: 90%; */
  }
}
@media screen and (max-width: 520px) {
  .oatimer {
    left: 76% !important;
    /* left: 90%; */
  }
}

@media only screen and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .oatimer {
    /* left: 78%; */
    /* top: 50px;
    height: 100%;
    position: absolute;
    background-size: cover;
    display: flex;
    align-items: center;
    border: 1px solid;
    height: 20px;
    padding: 5px;
    border-radius: 5px; */
  }
  .oatimer-clock-card {
    /* background: none !important;
    padding: 0;
    text-align: center !important;
    box-shadow: none !important;
    border: none !important; */
  }
  .oatimer-time {
    /* background-color: darkred;
    border-radius: 15px;
    color: #333 !important;
    font-size: 22px;
    letter-spacing: 2px;
    display: flex !important;
    font-family: "Jost";
    padding-left: 5px;
    padding-right: 5px; */
  }
  .oatimer-time span {
    display: none;
  }
}

/* @media only screen and (min-device-width: 320px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .oatimer {
    left: 0 !important;
    justify-content: center;
    top: 0 !important;
    margin-left: 81px;
    margin-top: 37px;
  }
  .oatimer-clock-card {
    background: none !important;
    padding: 0;
    text-align: center !important;
    box-shadow: none !important;
    border: none !important;
  }
  .oatimer-time {
    color: rgb(202, 49, 49) !important;
    font-size: 15px !important;
    letter-spacing: 2px;
    display: flex !important;
  }
} */

@media screen and (min-width: 1500px) {
  .oatimer {
    /* left: 640px !important; */
  }
}

.oatimer-time {
  color: #333;
  font-size: 0.76rem;
  letter-spacing: 2px;
}

.oatimer-clock-card {
  /* background: #007a92;
  padding: 0 11px;
  text-align: center;
  border-radius: 8px;
  border: 3px solid #e4666b;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.2); */
}
