.maincontent {
  display: flex;
  flex-direction: column;
}

.maincontent-background {
  display: flex;
  background: var(--search-panel-background-color);
  margin: 5% 8%;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  padding: 20px;
}

/* .buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  border: 2px solid blue;
} */

.button {
  display: flex;
  background: var(--primary-background-color);
  flex-direction: row;
  height: 100px;
  margin: 0 5px;
  width: 160px;
  height: 70px;
  margin-top: -50px;
  align-items: center;
  justify-content: space-around;
}

.search-wrapper {
  /* display: flex;
  flex-direction: column; */
  align-items: center;
  width: 100%;
  justify-content: center;
}

.mainicon {
  width: 40px;
  height: 40px;
  color: var(--secondary-text-color);
}

.button p {
  font-size: 13px;
  width: 80px;
  text-align: center;
  color: var(--secondary-text-color);
}

.button:active,
.button:hover {
  background: var(--secondary-background-color);
}
.button:active p,
.button:hover p {
  color: var(--ternary-text-color);
}
.button:active .mainicon,
.button:hover .mainicon {
  color: var(--ternary-text-color);
}

.first-line {
  display: flex;
  margin-top: 30px;
}

/* .search-wrapper * {
  color: blue !important;
  margin: 0 100px !important; 
} */

/* .search-wrapper  * {
  outline: none !important;
  border: 0 !important;
} */

.first-line > * {
  border: 0 !important;
}

.date-content > * {
  outline: none !important;
  border: 0 !important;
}

.date-content > * ::after {
  outline: none !important;
  border: 0 !important;
}
.date-content > * ::before {
  outline: none !important;
  border: 0 !important;
}

.date-content > * :hover {
  outline: none !important;
  border: 0 !important;
}

.date-content > * :hover {
  outline: none !important;
  border: 0 !important;
}

.from-to-selection-content > * :hover {
  outline: none !important;
  border: 0 !important;
}

.from-to-selection-content > * :focus {
  outline: none !important;
  border: 0 !important;
  background-color: transparent !important;
}

.from-to-selection-content > * ::after {
  outline: none !important;
  border: 0 !important;
  background-color: transparent !important;
}
.from-to-selection-content > * ::before {
  outline: none !important;
  border: 0 !important;
  background-color: transparent !important;
}

.from-to-selection {
  display: flex;
  background: var(--primary-background-color);
  width: 90%;
  padding: 0 2%;
  height: 60px;
}

.from-to-selection-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-direction: row;
}

.date-content {
  padding: 0 !important;
}

.iconbg {
  display: flex;
  height: 60px;
  width: 50px;
  background: var(--primary-background-color);
  align-items: center;
  justify-content: center;
}

.switchicon {
  width: 25px;
  height: 25px;
  align-self: center;
  color: var(--quaternary-text-color);
}

.from-to-text {
  color: var(--secondary-text-color);
  font-size: 0.8rem;
  justify-self: flex-start;
}

.calendaricon {
  width: 25px;
  height: 25px;
  align-self: center;
  color: var(--secondary-text-color);
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}

.date p {
  font-size: 1rem;
  color: var(--primary-text-color);
  margin: 0;
}

.combo-box-demo {
  display: flex;
  justify-content: space-around;
  align-content: center;
  padding-top: 10;
  width: 300px;
  height: 100%;
}

.autocomplete {
  padding: 0px 10px;
  /* padding-bottom: -5px; */
}

.second-line {
  margin-top: 40px;
}

.dropdownicon {
  width: 35px;
  height: 35px;
  align-self: center;
  color: var(--secondary-text-color);
}

.third-line {
  display: flex;
  width: fit-content;
  justify-content: center;
  padding: 0 35px;
  margin-top: 40px;
  background: var(--ternary-background-color);
  color: var(--ternary-text-color);
  font-weight: bold;
  padding: 10px 15px;
  cursor: pointer;
}

.third-line p {
  margin: 0;
  font-size: 1.1rem;
}

.maincontent-background.dark {
  margin: 0;
  margin-bottom: -20px;
  margin-top: 30px;
  background: var(--search-panel-background-color);
  width: 100%;
  /* justify-self: baseline; */
}

.other-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.other-wrapper p {
  color: var(--ternary-text-color);
}

.other-wrapper-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.other-icon-bg {
  display: flex;
  width: 120px;
  height: 120px;
  background: #aaa;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.other-icon {
  width: 50px;
  height: 50px;
  color: rgb(165, 159, 159);
  padding: 20px;
}

.other-wrapper-options p {
  font-size: 0.7rem;
}

.button_mobile {
  width: 70px;
}

.inputfield {
  text-decoration: none;
  border: none;
  font-size: 1rem;
  outline: none;
}

/* .inputfield: */

.button_mobile p {
  display: flex;
  color: var(--ternary-text-color);
  justify-content: center;
}

.mainicon_mobile {
  width: 60px;
  height: 80px;
  background: var(--ternary-text-color);
  padding: 10px 10px;
  color: var(--secondary-text-color);
}

.mainicon_mobile:active,
.mainicon_mobile:hover {
  background: var(--secondary-text-color);
  color: var(--ternary-text-color);
}

@media (max-width: 800px) {
  .search-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .first-line {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .from-to-selection {
    margin: 10px 0;
  }
  .iconbg {
    display: none;
  }
  .second-line {
    display: flex;
    flex-direction: column;
    /* margin: 10px 0; */
    width: 100%;
    align-items: center;
    /* margin: 0; */
  }

  .button {
    display: none;
  }
  .buttons {
    justify-content: space-evenly;
  }
  .button_mobile p {
    text-align: center;
    font-size: 0.7rem;
  }
  .from-to-selection {
    display: flex;
    justify-content: center;
  }
  .third-line {
    display: flex;
    align-self: center;
    margin-top: 10px;
    margin-left: 0;
  }
  .from-to-selection-content {
    justify-content: space-between;
    padding: 0 30px;
  }
  .other-wrapper-options {
    align-self: center;
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .other-icon-bg {
    margin: 10px;
  }

  .MuiTab-root {
    min-width: 50px;
    border: 3px solid var(--primary-text-color);
  }
}

@media (min-width: 801px) {
  .button_mobile {
    display: none;
  }
  .first-line {
    margin-top: 70px;
  }
  .second-line {
    max-width: 35%;
  }
}

/* .MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}
  */

.search-result-header {
  /* font-family: Helvetica Neue;
  font-style: normal; */
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 1.33px;

  color: #000;
  text-transform: uppercase;
}

.search-result-header-small {
  /* font-family: Helvetica Neue;
  font-style: normal; */
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.83px;

  color: var(--quaternary-text-color);
  text-transform: uppercase;
}

.search-result-header-medium {
  /* font-family: Helvetica Neue;
  font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 1.17px;

  color: #000;
}

.exchange-over {
  width: 30px !important;
  height: 30px !important;
}

.result-item-travel-name {
  /* font-family: Helvetica Neue;
  font-style: normal; */
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.63px;

  color: #000000;
}

.result-item-bus-type {
  /* font-family: Helvetica Neue;
  font-style: normal; */
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.63px;

  color: #000000;
}

.result-item-city-name {
  /* font-family: Helvetica Neue;
    font-style: normal; */
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.89px;

  color: #000000;
}

.result-item-city-time {
  /* font-family: Helvetica Neue;
    font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 1px;

  color: #000000;
}

.result-item-duration {
  /* font-family: Helvetica Neue;
    font-style: normal; */
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.63px;
  color: #706f70;
}

.result-item-seat-left {
  /* font-family: Helvetica Neue;
    font-style: normal; */
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.63px;
  color: #f76b6b;
}

.result-item-price-title {
  /* font-family: Helvetica Neue;
    font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.63px;
  color: #706f70;
}

.result-item-commission {
  /* font-family: Helvetica Neue;
    font-style: normal; */
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #706f70;
  margin-right: 10px;
}
.result-item-price {
  /* font-family: Helvetica Neue;
    font-style: normal; */
  /* font-weight: 500; */
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 1.33px;
  color: #f76b6b;
}

.result-item-select-button {
  /* font-family: Helvetica Neue !important;
    font-style: normal !important; */
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  /* identical to box height */
  letter-spacing: 1px !important;
  color: #ffffff !important;
  background: #f76b6b !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  cursor: pointer;
  min-width: 100px !important;
  padding: 6px 15px;
}

.result-item-select-button-over {
  /* font-family: Helvetica Neue !important;
    font-style: normal !important; */
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  /* identical to box height */
  letter-spacing: 1px !important;
  color: #f76b6b !important;
  background: #ffffff !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  cursor: pointer;
  min-width: 100px !important;
  border: 2px solid #f76b6b !important;
}

.result-item-select-button-r0 {
  /* font-family: Helvetica Neue !important;
    font-style: normal !important; */
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  /* identical to box height */
  letter-spacing: 1px !important;
  color: #ffffff !important;
  background: #2f71af !important;
  /* border-radius: 8px !important; */
  text-transform: capitalize !important;
  cursor: pointer;
  min-width: 100px !important;
}

.result-item-select-button-r0-over {
  /* font-family: Helvetica Neue !important;
    font-style: normal !important; */
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  /* identical to box height */
  letter-spacing: 1px !important;
  color: #f76b6b !important;
  background: #ffffff !important;
  /* border-radius: 8px !important; */
  text-transform: capitalize !important;
  cursor: pointer;
  min-width: 100px !important;
  border: 2px solid #f76b6b !important;
}

.rotate-column-test {
  display: flex;
  flex-direction: column;
  -webkit-transform: rotate(-90deg);
  /* margin: -125px; */
  margin-top: -30%;
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.result-item-smallest-text {
  /* font-family: Helvetica;
    font-style: normal; */
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  border-radius: 15px;
  background: #007a92;
  padding: 7px 10px;
  margin-right: 10px;
  color: #ffffff;
  /* padding-left: 7px;
    padding-right: 7px; */
}

.result-item-details {
  /* font-family: Helvetica;
    font-style: normal; */
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  margin-right: 10px;
  color: #f76b6b;
}

.search-result-layout {
  background: #f4f4f4;
  border-radius: 15px;
  width: 100%;

  min-height: 200px;
  display: flex;
}

.search-result-layout-point {
  /* font-family: Helvetica Neue;
font-style: normal; */
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.525px;
  text-transform: uppercase;
  color: #ed0202;
}

.search-result-layout-point-select {
  width: 170px;
  height: 26px;

  /* font-family: Roboto;
font-style: normal; */
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.714286px;

  color: #5e5e5e;
}

.search-result-layout-label {
  /* font-family: Helvetica Neue;
font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.63px;

  color: #000000;
}

.search-result-layout-chair-label {
  /* font-family: Helvetica Neue;
font-style: normal; */
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.45px;

  color: #706f70;
}

.search-result-layout-selected-seat-label {
  /* font-family: Helvetica Neue;
font-style: normal; */
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #706f70;
}

.search-result-layout-selected-seat-count {
  /* font-family: Helvetica Neue;
font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  letter-spacing: 1.08px;

  color: #000000;
  margin-right: 10px;
}
.search-result-layout-selected-seat-label1 {
  /* font-family: Helvetica Neue;
font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.63px;

  color: #000000;
}
.search-result-layout-selected-seat-price {
  /* font-family: Helvetica Neue;
font-style: normal; */
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;
  /* identical to box height */

  letter-spacing: 1.9px;

  color: #f76b6b;
}

.bus-available-seat {
  width: 35px;
  height: 35px;

  border: 1px solid #979797;
}

.bus-ladies-seat {
  width: 35px;
  height: 35px;
  border: 1px solid #f76b6b;
}

.bus-booked-seat {
  width: 35px;
  height: 35px;

  background: #dedede;
  border: 1px solid #979797;
}

.bus-gents-seat {
  width: 35px;
  height: 35px;

  border: 1px solid #e3a130;
}

.bus-seat-type-seat {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bus-seat {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  /* font-family: Helvetica Neue;
    font-style: normal; */
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.54px;

  color: #bcbcbc;
}

.sleeper {
}

.seat-cum-sleeper {
}

.upper-berth {
}

.lower-berth {
}

.bus-seat:hover {
  background: #dedede;
  border: 1px solid #979797;
}

/* .bus-seat:active{
    background: #456b43;
    border: 1px solid #979797;
    color: white;
  } */

.transform-div-90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media (max-width: 768px) {
  .MuiTab-root {
    min-width: 40px !important;
  }
  .MuiTabs-root {
    flex: 1 !important;
  }

  .OaIconTabBar-tabStyle-252 {
    margin-right: 0 !important;
    margin: 0 5px !important;
  }

  .OaIconTabBar-activeTabStyle-253 {
    margin-right: 0 !important;
    margin: 0 5px !important;
  }

  .OaIconTabBar-iconLabelWrapper-250 {
    margin: 0 !important;
  }

  .OaIconTabBar-iconLabelWrapperActive-251 {
    margin: 0 !important;
  }
}

/* .MuiOutlinedInput-notchedOutline {
  border: none !important;
} */

.date-selection > .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-text-color);
}

.date .MuiFormControl-fullWidth {
  width: 50%;
}

.date-content {
  display: flex;
}

.search-div-span {
  color: rgba(0, 0, 0, 0.35);
}

.from-to-selection .date-content .MuiIconButton-root {
  color: #f76b6b !important;
}

/* #bus-date-picker-inline{
  width: 200px;
  border-bottom: 2px solid #666666;
  
} */
/* 
#bus-date-picker-inline:focus{
  width: 100%;
  border-bottom: 2px solid #666666;
} */
