/*ticket-custom-div-css*/
/* body {
	background: #3b3b3b;
	font-family: 'Montserrat', sans-serif !important;
	line-height: 1.5em;
} */
:root {
	--primary: #DDDDDD;
	--contrtext: #333333;
	--danger: #EC6161;
}

.hr1 {
	margin: 0 !important;

}

.container-print {
	border: 1px solid black;
	width: auto;
	width: 980px;
	height: auto;
	/*margin-left: 18%; 
	margin-right: 18%;*/
	background: white;
}

@media all and (max-width: 1279px) {
	.container-print {
		width: 100% !important;
	}
}

.content-wrapper {
	padding-left: 1em;
	padding-right: 1em;
	position: relative;
	min-height: 80%;
}

.p1 {
	margin: 0px !important;
}

.table1 {
	background: white;
	border-collapse: collapse;
	width: 100%;
}

.ticket-header-info .tr1 .td1 {
	text-wrap: nowrap !important;
}
/* hr {
	margin: 0px;
} */

.th1 {
	color: var(--contrtext);
}

.td1,
.th1 {
	text-align: center;
	border: 1px solid var(--primary);
}

.table1 .td1 .p1 {
	font-size: 12px;
	font-weight: 500;
	color: var(--contrtext);
	text-transform: capitalize;
}

.thead1 .tr1 {
	height: 1.5em;
}

.ul1 .li1 {
	margin: 0px;
	font-weight: 500;
	color: #333333;
}

section {
	clear: both;
}

.w2 {
	width: 48%;
}

.left {
	float: left;
}

.right {
	float: right;
}

.logo {
	/* width: 20%; */
	float: left;
}

.label-print {
	/* font-size: 1.2em; */
	font-size: 12px;
	/* height: 2em; */
	/* margin-left: 1em; */
	border-radius: 14px;
	width: 14em;
	color: white;
	/* text-align: center; */
	margin-bottom: -0.6em;
	/* padding: 1.5%; */
	padding: 5px 0 15px;
}

.airline-logo {
	width: 70%;
}

.plane-icon {
	width: 16%;
	float: left;
}

.align-text-left {
	padding-left: 0.5em !important;
	text-align: left;
}

.align-text-right {
	padding-right: 0.5em !important;
	text-align: right;
}

.redline {
	background: var(--primary);
	width: 100%;
	height: 30px;
	max-height: 30px !important;
	padding-top: 1px;
	/* margin-bottom: 5px; */
}

.redline .p1 {
	font-size: 8.85pt;
	text-align: center;
	color: var(--contrtext);
}

.booking-info {
	float: right;
	/* width: 70%; */
	width: auto;
}

.booking-info .table1 {
	border-collapse: collapse !important;
	float: right;
	width: 120px;
}

.booking-info .company-brand-name {
	text-align: left;
	font-size: 26px;
	margin-bottom: 6px;
	padding: 0 0 0 5px;
	display: block;
}

.booking-info .td1,
.booking-info .th1,
.booking-info .tr1 {
	border: none;
	padding: 0px;
	color: var(--contrtext);
	font-weight: 500;
}

.booking-info .td1 .p1 {
	margin: 0px !important;
	text-align: left;
	font-size: 0.8em;
}

.itinerary-section {
	position: relative;
	/* margin-top: 60px; */
}

.passengers-section {
	margin-top: 20px;
	margin-bottom: 20px;
}

.header-section {
	height: 8em;
	margin-top: 1%;
	margin-bottom: 1%;
}

.passenger-addon-detail p1 {
	font-size: 6px;
}

.fare-section {
	height: 14em;
	margin-bottom: 3%;
}

.fare-brakeup .table1 {
	height: 11.5em;
}

.fare-brakeup .thead1 .p1 {
	font-size: 0.5em;
}

.footer-section {
	margin: 0 0 15px 0;
}

.footer-section li1 {
	font-size: 7pt;
}

.footer-section h1 {
	width: 100%;
	text-align: center;
	font-size: 10pt;
}

.red {
	background: var(--primary);
}

.red .p1, .label-print .p1 {
	color: var(--contrtext);
}

.red .p1 {
	font-weight: 700;
    line-height: 1.5;
    font-size: 12px;
}

.red-text {
	color: var(--contrtext);
}

.bold {
	font-weight: 700;
}

.gray {
	background: var(--primary);
}

.city {
	text-transform: uppercase;
	/* font-size: 1.3em; */
	font-size: 14px;
	width: 16%;
	margin-top: 1%;
	margin-left: 3%;
	float: left;
	text-align: center;
}



.backrow-flightinfo {
	/* height: 14em; */
	height: 125px;
	border: 1pt solid var(--primary);
	border-radius: 0;
	padding-left: 3%;
	/* border-left: 1px solid; */
	margin-top: 2%;
	text-align: center;
	/* width: 100%; */
}

.flight-info-right-column {
	padding-left: 3%;
	border-left: 1px solid;
	float: right;
	margin-top: 2%;
	text-align: center;
	margin-right: 3em;
	width: 55%;
}

.flight-info-coloumn {
	width: 30%;
	float: left;
	margin-left: 0%;
	/* font-size: 1.2em; */
}

.additional-flight-info {
	text-align: center;
	font-size: 8pt;
	text-transform: uppercase;
	float: right;
	margin-right: 3%;
	margin-top: 1%;
	right: 2px;
	color: var(--contrtext);
	font-weight: 500;
}

.flight-info-duration {
	font-size: 10px;
	border-radius: 8px;
	width: 10em;
	color: white;
	width: 168px;
	margin-top: 5%;
	margin-left: 11%;
	/* height: 38px; */
	padding-top: 6px;
	padding-bottom: 5px;
}

@media (max-width: 1366px) {
	.flight-info-duration {
		width: 120px;
	}
}

.pnr-section {
	margin-top: -3px;
	z-index: 800;
	float: left;
	height: auto;
	text-align: center;
	padding: 1em;
	/* width: 9em; */
	/* border: 2pt solid var(--primary); */
	/* border-radius: 1em; */
	/* background: white; */
	/* height: 130px;
	width: 100px; */
	min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pnr-section-repeat {
	margin-top: 10px;
	z-index: 800;
	float: left;
	height: auto;
	text-align: center;
	padding: 1em;
	/* width: 9em; */
	border: 2pt solid black;
	border-radius: 1em;
	background: white;
}

.agency-contact {
	width: 100%;
	margin-bottom: 2%;
}

.agency-contact>tr,
.td1 {
	border-top: 0px;
	color: var(--contrtext);
	font-weight: 500;
	font-size: 12px;
}

.airline-contact {
	width: 100%;
}

.hr2 {
	margin: 0 !important;
	margin-top: 75px !important;

}

.hr2-over {
	margin-top: -2% !important;
}

.arrival-header {
	/* font-size: 1.7em */
	font-size: 15px;
}

.hr1,
.container-print,
.content-wrapper,
.p1,
.table1,
.th1,
.td1,
.thead1,
.tr1,
.ul1 .li1,
section,
.w2,
.left,
.right,
.logo,
.label-print,
.airline-logo,
.plane-icon,
.align-text-left,
.align-text-right,
.redline,
.booking-info,
.itinerary-section,
.passengers-section,
.header-section,
.passenger-addon-detail,
.fare-section,
.fare-brakeup,
.footer-section,
.city,
.backrow-flightinfo,
.flight-info-right-column,
.flight-info-coloumn,
.additional-flight-info,
.flight-info-duration,
.pnr-section,
.agency-contact,
.airline-contact {
	box-sizing: unset;
}

.footer-ribbon-redline-wrap {
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer-ribbon-redline {
	margin-bottom: 1%;
	font-size: 12px;
	/* width: 33em; */
	/* border-radius: 19px; */
	border: solid 1px var(--primary);
	/* background: var(--primary); */
	color: var(--danger);
	padding: 8px;
	text-align: center;
	margin-top: 25px;
	font-weight: 600;
}

.upper-case {
	text-transform: uppercase;
}


@page {
	margin: 0px;
	size: A4 portrait;
	orphans: 2;
	window: 2;
}

@media print {
	body {
		padding: 0px;
		background: white;
		margin: 0;
		display: inline;
	}

	.container-print {
		border: 1px solid black;
		width: 100% !important;
		/* height: auto !important; */
		margin: 0px;
		background: white;
		float: none !important;
		position: static !important;
		display: inline;
		box-sizing: content-box !important;
	}

	.arrival-header {
		font-size: 1em
	}

	.logo .img1 {
		float: left;
		width: 50%;
	}

	.city {
		text-transform: uppercase;
		/* font-size: 0.8em; */
		font-size: 14px;
		width: 15%;
		margin-top: 2%;
		float: left;
		text-align: center;
		height: 70px;
	}

	.flight-info-coloumn {
		width: 30%;
		font-size: 0.8em;
		float: left;
		margin-left: 3%;
	}

	.flight-info-right-column {
		padding-left: 3%;
		border-left: 1px solid;
		float: right;
		margin-top: 2%;
		text-align: center;
		margin-right: 3em;
		width: 45%;
	}

	.backrow-flightinfo {
		height: 8em;
		border: 2pt solid var(--primary);
		border-radius: 0;
	}

	.flight-info-duration {
		font-size: 11px;
		border-radius: 8px;
		width: 7em;
		color: white;
		/* width: 168px; */
		margin-top: 5%;
		margin-left: 11%;
		/* height: 38px; */
		padding-top: 6px;
	}

	.pnr-section-repeat {
		margin-top: 6px;
	}

	.hr1 {
		margin: 0 !important;

	}

	#section-to-print>div>div>div>div>div {
		padding: 0 !important;
	}

	#section-to-print>div>div>div>div {
		margin: 0 !important;
	}

	.page-break {
		/* display: block;
		page-break-before: auto; */

		-webkit-region-break-inside: avoid !important;
		page-break-before: always !important;
		page-break-after: always !important;
		page-break-inside: avoid !important;
		overflow: initial !important;
		float: none !important;
	}

	#section-2 {
		padding-top: 0 !important;
		margin: 0 !important;
	}

	#section-2>div>div>div {
		padding-top: 0 !important;
		margin: 0 !important;
	}

	.hr2 {
		margin: 0 !important;
		margin-top: 50px !important;
	}

	.hr2-over {
		margin-top: -2% !important;
	}

	.makeStyles-wrapper-1 {
		padding-top: 0 !important;
	}

	#root>div {
		padding-top: 0 !important;
	}

	.arrival-header-time {
		font-size: .8em;
	}

	.booking-info-txt {
		width: 12%;
	}

	#mainContainer>div[class*="makeStyles-container-"] {
		padding-top: 0 !important;
	}

	.MuiGrid-root>div[class*="makeStyles-card-"]>div[class*="makeStyles-cardBody-"] {
		padding: 0 !important;
	}

	.MuiGrid-root>div[class*="makeStyles-card-"] {
		margin-top: 0 !important;
	}


	#mainContainer>div[class*="jss"] {
		padding-top: 0 !important;
	}

	.MuiGrid-root>div[class*="jss"]>div[class*="jss"] {
		padding: 0 !important;
	}

	.MuiGrid-root>div[class*="jss"] {
		margin-top: 0 !important;
	}



	.header-section {
		height: 6em;
	}


	.bottom-footer {
		/* position: fixed; */
		/* bottom: 0px; */
		width: 100%;
		padding-top: 5px;
	}

	.container-print {
		border: none;
	}

	header {
		display: none;
	}

	/* .hr1, .container, .content-wrapper, .p1, .table1, .th1,  .td1,.thead1 ,.tr1,.ul1 .li1, section, .w2, .left, .right, .logo, .label-print, .airline-logo, .plane-icon, .align-text-left,
.align-text-right, .redline, .booking-info, .itinerary-section, .passengers-section
, .header-section,.passenger-addon-detail, .fare-section, .fare-brakeup, .footer-section, .city, .backrow-flightinfo, .flight-info-right-column, .flight-info-coloumn
,.additional-flight-info, .flight-info-duration, .pnr-section, .agency-contact, .airline-contact{
	
	line-height: initial;
	font-weight: initial;
} */


}

@media all and (max-width: 768px) {
	/* .itinerary-section {
		display: flex;
		flex-direction: column;
	}
	.backrow-flightinfo {
		display: flex;
		flex-direction: column;
		height: auto;
		width: auto;
	}
	.backrow-flightinfo .city {
		width: 100%;
	}
	.backrow-flightinfo .flight-info-right-column {
		padding-left: unset;
		border-left: unset;
		margin-right: unset;
		width: auto;
	} */
	.flight-info-duration {
		width: auto;
	}
	.fare-section .row1 {
		display: flex;
		flex-direction: column;
	}
	.fare-section .w2.right.col {
		margin-top: 5px;
		width: 100% !important;
	}
	.fare-section .w2.left.fare-brakeup.col {
		width: 100% !important;
	}
	.footer-ribbon-redline-wrap .footer-ribbon-redline {
		margin-left: 0 !important;
		width: 100%;
	}
	.section-not-to-print .oa-card-body {
		display: flex;
	}
	.section-not-to-print .oa-card-body button {
		width: auto;
		padding: 2px 10px;
	}
}
