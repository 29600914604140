

    body {
      font: 400 15px Montserrat, sans-serif;
      /* line-height: 1.8; */
      color: #4c4a4a;
    }
    
  a.left.carousel-control img {
    width: 31px;
    
    margin-top: 49px;
    
    margin-left: -46px;
}
a.right.carousel-control img {
    width: 31px;
     margin-top: 49px;
    
    margin-left: 8px;
}

  .social {
   
    text-align: left;
    color: #000;
}
.social i.fa {
  padding: 4px 12px 3px 2px !important;
 font-weight: 500;
}


  span.red {
    color: #f11c1c;
    font-size: 12px;
    padding-top: 17px !important;
}
footer{
  text-align: left;
}
.mb-4.mb-md-0.contat p {
  text-align: justify;
  color: #000;
  font-weight: 600;
}

.mb-4.mb-md-0.contat h3{

  text-align: left;
}


h3.orng {
  color: #007A92ee;
  font-weight: 700;
}
h3.gren{
  color: #43B253;
  font-weight: 700;
}

div#services {
  border-bottom: 6px solid #007A92ee;
       padding-bottom: 50px;
    padding-top: 39px;
}


.mb-4.mb-md-0 {
  padding-top: 41px;
  margin-bottom: 40px !important;
}

footer.bg-white {
    background: #fff;
    border-top: 6px solid #43b253;
    margin-bottom: 31px !important;
}

    @media (max-width: 480px){

      
        .banr {
          
          width: 100%  !important;
      }
      .block-title-content {
        display: none;
       
    }
    .row.banr-img{

      background-size: 100% !important;
      background-repeat: no-repeat !important;
      height: 200px !important;
      margin-top: 68px !important;
    }
    }
    
    
.row.top-sec {
    background: #fff
}
#about{
 background: #fff;

}
img.img-responsive.cntr {
  width: 220px;
  margin-top: 40px;
}
    h2 {
      font-size: 24px;
      text-transform: uppercase;
      color: #303030;
      font-weight: 600;
   
    }
    h4 {
      font-size: 19px;
      line-height: 1.375em;
      color: #908c8c;
      font-weight: 600;
   
    }  
  .sec2 {
    margin-top: 30px;
      }


    h4.gren {
      margin-top: 38px;
      color: #2ce843 !important;
  }
    .jumbotron {
      background-color: #fff;
      color: #000;
      padding: 100px 25px;
      font-family: Montserrat, sans-serif;
    }
    .log.btn .btn {
      background: #f4511e;
      color: #fff;
  }
  .login-icon-position {
    font-size: 16px;
    margin-top: 13px;
    margin-right: 10px;
    z-index: 1;
}
button.orange-btn {
  background: #f4921f;
  border: none;
  color: #fff;
  padding: 5px 18px;
  margin: 1px 9px;
  border-radius: 2px;
}


.single-tab-select-box {
    padding: 13px 10px;
}
form.frm-dsn {
    margin: 8px;
}

span.glyphicon{
  color: #f4921f;
}
  .travel-select-icon {
    position: relative;
}
    .bg-grey {
      background-color: #f6f6f6;
    }
    .logo-small {
      color: #f4511e;
      font-size: 50px;
    }
    .logo {
      color: #f4511e;
      font-size: 200px;
    }
    .thumbnail {
      padding: 0 0 15px 0;
      border: none;
      border-radius: 0;
    }
    .thumbnail img {
      width: 100%;
      height: 100%;
      margin-bottom: 10px;
    }
    .carousel-control.right, .carousel-control.left {
      background-image: none;
      color: #f4511e;
    }
    .carousel-indicators li {
      border-color: #f4511e;
    }
    .carousel-indicators li.active {
      background-color: #f4511e;
    }
    .item h4 {
    font-size: 17px;
    
    font-weight: 600;
   color: #000;
    margin-bottom: 1px;
    margin-top: 0px;
    padding: 6px 0 0 0;
  }
  .item p {
    font-size: 12px;
    margin-bottom: 1px;
    margin-top: 0px;
    padding: 1px 28px 0 0;
  
}

    .item span {
    font-size: 12px;
    padding: 6px 38px 0 0;
    color: red;
}
    .panel {
      border: 1px solid #f4511e; 
      border-radius:0 !important;
      transition: box-shadow 0.5s;
    }
    .panel:hover {
      box-shadow: 5px 0px 40px rgba(0,0,0, .2);
    }
    .panel-footer .btn:hover {
      border: 1px solid #f4511e;
      background-color: #fff !important;
      color: #f4511e;
    }
    .panel-heading {
      color: #fff !important;
      background-color: #f4511e !important;
      padding: 25px;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .panel-footer {
      background-color: white !important;
    }
    .panel-footer h3 {
      font-size: 32px;
    }
    .panel-footer h4 {
      color: #aaa;
      font-size: 14px;
    }
    .panel-footer .btn {
      margin: 15px 0;
      background-color: #f4511e;
      color: #fff;
    }
    .navbar {
      margin-bottom: 0;
      background-color: #fff;
      z-index: 9999;
      border: 0;
      font-size: 12px !important;
      line-height: 1.42857143 !important;
      
      border-radius: 0;
      font-family: Montserrat, sans-serif;
    }
    .navbar li a, .navbar .navbar-brand {
      color: #000 !important;
      font-weight: 600;
      font-size: 14px;
  }


  .navbar-nav {
   
    padding: 6px 6px 4px 38px;
}
.btn2{
  background: #000 !important;
  border: 2px solid #F89605 !important;
  font-weight: 550 !important;
  color: #F89605 !important;
  line-height: 1.5 !important;
  width: 190px;
  font-size: 17px;
}
  button.orange2-btn {
    background: #F89605;
    border: none;
   
    font-weight: 550;
    color: #000;
    padding: 6px 15px;
    margin: 13px 5px;
    border-radius: 5px;
}


    .navbar-nav li a:hover, .navbar-nav li.active a {
      color: #f4511e !important;
      background-color: #fff !important;
    }
    .navbar-default .navbar-toggle {
      border-color: transparent;
      color: #fff !important;
    }
    footer .glyphicon {
      font-size: 20px;
      margin-bottom: 20px;
      color: #f4511e;
    }
    .slideanim {visibility:hidden;}
    .slide {
      animation-name: slide;
      -webkit-animation-name: slide;
      animation-duration: 1s;
      -webkit-animation-duration: 1s;
      visibility: visible;
      margin-top: 15px;
    }

   .copen  h5 {
      font-weight: bold;
      color: #000;
  }

.copen {
    text-align: justify;
}
.copen p
{

  font-size: 12px;;
} 
.banr 
{
      background: #fff;
    margin: 5px;
    width: 32% ;
    float: left;
    padding-right: 32px;
}
@keyframes slide {
      0% {
        opacity: 0;
        transform: translateY(70%);
      } 
      100% {
        opacity: 1;
        transform: translateY(0%);
      }
    }
    @-webkit-keyframes slide {
      0% {
        opacity: 0;
        -webkit-transform: translateY(70%);
      } 
      100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
      }
    }
    @media screen and (max-width: 768px) {
      .col-sm-4 {
        text-align: center;
        margin: 25px 0;
      }
      .btn-lg {
        width: 100%;
        margin-bottom: 35px;
      }
    }
    @media screen and (max-width: 480px) {
      .logo {
        font-size: 150px;
      }
    }
    @media only screen and (min-width: 992px) {


form.frm-dsn {
 
    margin: 46px 1px 0px 0px;
}
    }

    .row.banr-img{
      background: url(../img/banner1.jpg) ;
            
            height: 550px;
            margin-top: 1px;
            background-size: 100%;
    }
    #top-navb{

      border-bottom: 1px solid #ccc !important;
    }
    p.txt-set {
      height: 278px;
      font-size: 14px;
  }

  @media screen and (max-width: 480px){


#top-navb{
  display: block;
}
}
