#students {
    /* text-align: center; */
    /* font-family: "Trebuchet MS", Arial, Helvetica, sans-serif; */
    border-collapse: collapse;
    /* border: 3px solid #ddd; */
    width: 100%;
  }
  
  #students td, #students th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 12px;
  }
  
  /* #students tr:nth-child(odd){background-color: #f2f2f2;} */
  
  /* #students tr:hover {background-color: #ddd;} */
  
 #students tr {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f2f2;
    color: #636060;
    font-size: 12px;
  } 
  #students td{
background-color: #ffffff;
color: #636060;

  }
  /* tr:first-child{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f2f2 !important;
    color: #636060;
    font-size: 12px;
    font-weight: 700;
  } */
  

  .arrow-crousel{
    width: 0;
    /* height: 10,
    alignItems: "center",
    marginTop: "12%",
    background: "red",
    cursor: "pointer",
    marginRight: 10, */
    align-items: center;
    margin-top: 12% ;
    cursor: pointer;
    margin-right: 10px;
    
  }
  .arrow-crousel-offer{
    align-items: center;
    margin-top: 8% ;
    cursor: pointer;
    margin-right: 10px;
    width: 0;
  }

  @media screen and (min-width: 1200px)and (max-width: 1700px){
    .arrow-crousel{
      margin-top: 12%;
    }
  }

  @media screen and (min-width: 1700px){
    .arrow-crousel{
      margin-top: 8% !important;
    }
  }

  @media screen and (max-width: 600px){
    .arrow-crousel{
      margin-top: 35% !important;
    }
  }

  @media screen  and (min-width: 320px)and (max-width: 600px){
    .arrow-crousel-offer{
      margin-top: 25% !important;
    }
  }