.hotel-price {
  font-size: 12px;
  letter-spacing: 0.3575px;
  color: #bcbcbc;
}

.hotel-price-exclude {
  font-size: 12px;
  letter-spacing: 0.3575px;
  text-decoration-line: line-through;

  color: #bcbcbc;
}

.MuiTabs-scroller {
  height: 40px;
}

.MuiBox-root-86 {
  padding: 0;
}
