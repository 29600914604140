:root {
  --primary-text-color: #000000;
  --secondary-text-color: #f76b6b;
  --ternary-text-color: #fff;
  --quaternary-text-color: #e3a130;
  --light-text-color: #706f70;

  --primary-background-color: #ffffff;
  --secondary-background-color: #007A92;
  --ternary-background-color: #e3a130;
  --search-panel-background-color: #000000;

  --primary-header-background-color: #4c4c4c;
  --secondary-header-background-color: #1374b3;
  --ternary-header-background-color: #004c7e;
}
