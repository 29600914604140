@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?mwau9g");
  src: url("fonts/icomoon.eot?mwau9g#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?mwau9g") format("truetype"),
    url("fonts/icomoon.woff?mwau9g") format("woff"),
    url("fonts/icomoon.svg?mwau9g#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Bauhaus";
  src: url("fonts/BAUHS93.TTF") format("truetype");
  font-weight: bold;
  font-style: bold;
  font-display: block;
}
@font-face {
  font-family:"century gothic";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/GOTHIC.TTF") format("truetype"),
       url("fonts/GOTHICB.TTF") format("truetype"),
       url("fonts/GOTHICB0.TTF") format("truetype"),
       url("fonts/CenturyGothic.ttf") format("truetype"),
       url("fonts/GOTHICBI.TTF") format("truetype"),
      url("fonts/GOTHICI.TTF") format("truetype");
       
  font-display: block;
}
@font-face {
  font-family:"cerapro";
  src: url("fonts/cera-pro/CeraPro-Medium.eot");
  src: url("fonts/cera-pro/CeraPro-Medium.eot") format("embedded-opentype"),
    url("fonts/cera-pro/CeraPro-Medium.ttf") format("truetype"),
    url("fonts/cera-pro/CeraPro-Medium.woff") format("woff"),
    url("fonts/cera-pro/CeraPro-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family:"cerapro-bold";
  src: url("fonts/cera-pro/CeraPro-Bold.eot");
  src: url("fonts/cera-pro/CeraPro-Bold.eot") format("embedded-opentype"),
    url("fonts/cera-pro/CeraPro-Bold.ttf") format("truetype"),
    url("fonts/cera-pro/CeraPro-Bold.woff") format("woff"),
    url("fonts/cera-pro/CeraPro-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bus_icon:before {
  content: "\42";
}
.icon-flight_icon:before {
  content: "\46";
}
.icon-train_icon:before {
  content: "\54";
}
.icon-hotel_icon:before {
  content: "\48";
}
.icon-money_transfer_icon:before {
  content: "\4d";
}

.icon-recharge_icon:before {
  content: "\R";
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 700;
}

.loader-text {
  margin-right: 10px;
}
.loader-dots {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-top: 4px;
}

.dot1,
.dot2,
.dot3 {
  background: #fff;
  width: 5px;
  height: 5px;
  border: double;
  border-color: #ffffff;
  border-radius: 50%;
  margin: 10px;
}
.dot1 {
  animation: jump 1.6s -0.32s linear infinite;
  background: #ffffff;
}
.dot2 {
  animation: jump 1.6s -0.16s linear infinite;
  background: #ffffff;
}
.dot3 {
  animation: jump 1.6s linear infinite;
  background: #ffffff;
}
@keyframes jump {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}


.spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #999;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
.placeholder {
  background: #f8f8f8;
  height: 200px;
  margin: 10px 0;
  padding-top: 80px;
}

@keyframes blinking {
  0% {
    background-color: #c2d87e;
    border: 3px solid #c2d87e;
    color: #333;
  }
  100% {
    background-color: #ef6614;
    border: 3px solid #ef6614;
    color: #fff;
  }
}
