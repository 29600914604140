.plane-container {
  /* width: 66vw; */
  /* padding: 1rem; */
  width: 60%;
  position: relative;
  max-height: 410px;
  overflow-y: scroll;
  /* margin-left: 2rem; */
  /* height: 90vh; */
}

.info-container {
  /* width: 66vw; */
  /* padding: 1rem; */
  width: 40%;
  position: relative;
  border-right: 1px solid #eee;
  /* margin-left: 2rem; */
  /* height: 90vh; */
}

@media screen and (max-width: 920px) {
  .plane-container {
    width: 60%;
  }

  .info-container {
    /* width: 66vw; */
    /* padding: 1rem; */
    width: 40%;

    /* margin-left: 2rem; */
    /* height: 90vh; */
  }
}

.main-container {
  /* width: 66vw; */
  /* padding: 1rem; */
  width: 100%;
  display: flex;
  position: relative;
  /* margin-left: 2rem; */
  /* height: 90vh; */
}

.plane {
  position: relative;
  margin: 20px auto;
  /* margin-right: 0px; */
  /* overflow-y: scroll; */
  overflow-x: hidden;
  /* overflow: hidden; */
  max-width: 350px;
  margin-top: 5px;
  /* max-height: 90vh; */

  padding: 0.3rem 1rem;
}

@media screen and (max-width: 400px) {
  .plane {
    padding: 0 10px 0 0;
  }
}

/* width */
/* .plane::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* .plane::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* .plane::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 30px;
} */

/* Handle on hover */
/* .plane::-webkit-scrollbar-thumb:hover {
  background: #1d1d1d;
} */

.cockpit {
  height: 140px;
  width: 345px;
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 0.5rem;
  /* border-bottom: 5px solid #d8d8d8; */
}

.cockpit:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -20px;
  height: 500px;
  width: 100%;
  border-radius: 50%;
  border-right: 5px solid #d8d8d8;
  border-left: 5px solid #d8d8d8;
}

.cockpit h1 {
  width: 60%;
  margin: 50px auto 35px auto;
  font-size: 1rem;
  margin-right: 80px;
}

.exit {
  position: relative;
  height: 50px;
}

.exit:before,
.exit:after {
  content: "EXIT";
  font-size: 14px;
  line-height: 18px;
  padding: 0px 2px;
  font-family: "Arial Narrow", Arial, sans-serif;
  display: block;
  position: absolute;
  background: green;
  color: white;
  top: 50%;
  padding: 0.5rem;
  transform: translate(0, -50%);
}

.exit:before {
  left: 0;
}

.exit:after {
  right: 0;
}

.exit--front {
  border-top: 5px solid #d8d8d8;
}

.exit--back {
  border-bottom: 5px solid #d8d8d8;
}

.cabin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fuselage {
  /* padding: 0  0.5rem; */
  border-right: 5px solid #d8d8d8;
  border-left: 5px solid #d8d8d8;
}

ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.seats {
  display: flex;
  /* gap:0.3rem; */
  align-items: center;
  /* flex-direction: row; */
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
}

.seat {
  display: flex;
  flex: 0 0 14.2857142857%;
  padding: 5px;
  width: 3rem;
  height: 3rem;
  position: relative;
}

.seat:nth-child(3) {
  margin-right: 14.2857142857%;
}

/* .seat input{
  z-index: 111;
} */
.seat input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.seat input[type="checkbox"]:checked+label {
  background: #bada55 !important;
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.seat .selection-restricted+label {
  cursor: not-allowed;
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5rem;
  padding: 4px 0;
  background: rgb(186, 218, 255) !important;
  /* background: rgb(0,0, 0); */
  border-radius: 5px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  color: #000;
  content: unset !important;
}

.seat input[type="checkbox"]:disabled:not(.selection-restricted)+label {
  background: #dddddd;
  text-indent: -9999px;
  overflow: hidden;
}

.seat input[type="checkbox"]:disabled:not(.selection-restricted)+label:after {
  content: "X";
  text-indent: 0;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.seat input[type="checkbox"]:disabled+label:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.seat label {
  display: block;
  position: relative;
  /* width: 100%; */
  width: 32px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5rem;
  padding: 4px 0;
  background: rgb(186, 218, 255);
  /* background: rgb(0,0, 0); */
  border-radius: 5px;
  animation-duration: 300ms;
  animation-fill-mode: both;
  color: #000;
}

.seat label:before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: 1px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}

.seat label:hover {
  cursor: pointer;
  box-shadow: 0 0 0px 3px #5c6aff;
}

.seat .show-info {
  background: #eee;
  width: max-content;
  /* position: relative; */
  position: absolute;
  z-index: 1111 !important;
  display: flex;
  gap: 0.5rem;
  top: -66%;
  /* left: -136%; */
  padding: 0.5rem 1rem;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 111;
}

.seat .show-info::before {
  content: "";
  position: absolute;
  bottom: -16%;
  width: 1.3rem;
  height: 1.3rem;
  transform: rotate(60deg);
  background: #eee;
  z-index: -1;
  left: 40%;
}

.seat .show-info span {
  font-size: 1.1rem;
}

.seat label:hover+.show-info {
  visibility: visible;
}

.seat label:focus+.show-info {
  visibility: visible;
}

.seat .show-info.abs-A {
  left: 0;
  right: unset;
}

.seat .show-info.abs-B {
  left: -50px;
  right: unset;
}

.seat .show-info.abs-C {
  left: -100px;
  right: unset;
}

.seat .show-info.abs-D {
  right: -100px;
  left: unset;
}

.seat .show-info.abs-E {
  right: -50px;
  left: unset;
}

.seat .show-info.abs-F {
  right: 0;
  left: unset;
}

.seatsSelectedContainer {
  border-top: 1px solid #e1e1e1;

  background-color: #fff;
  padding: 10px;
  margin-top: 35px;
  height: 250px;
  overflow: scroll;
}

.seatSelectedContainer {
  background-color: #eee;
  padding: 10px;
  margin-bottom: 3px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}

.seatInfo {
  display: flex;
}

.seatCodeSelected {
  background-color: green;
  color: #fff;
  font-size: 11px;
  padding: 3px;
  border-radius: 5px;
  margin-left: 10px;
}

.priceText {
  font-size: 13px;
}

.paxName {
  font-weight: 500;
}

.fareContainer {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 3px;
  font-size: 13px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

/* seat info */

.seat-info {
  /* width: 8rem; */
  width: 90%;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  /* width: 0rem; */
  text-align: center;
  /* background: rgba(0, 0, 0, 0.311); */

  position: absolute;
  bottom: 10px;
  /* left: 6%; */
  /* left: 1%; */
  box-shadow: 0 0 3px #000;
  border-radius: 6px;
  z-index: 111;
  padding: 10px;
}

.seat-info p {
  position: relative;
  width: 80px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #000;
  margin-left: 10px;
  margin-bottom: 10px;
  /* padding: 8px; */
}

.seat-info p>span {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  color: #000;
  padding: 0.6rem;
  line-height: 1;
  /* margin-right: 0.5rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat-info .exit-sign {
  background: green;
}

.seat-info .unbooked {
  background: rgb(186, 218, 255);
}

.seat-info .booked-sign {
  background: #dddddd;
  color: #000;
  font-weight: bold;
}

.seat-info .selected-seat {
  background: #bada55;
}

.seat-info .free-seat {
  background: rgb(80, 227, 194);
}

/* carousel */
.rec.rec-arrow:disabled {
  visibility: hidden;
}

.rec.rec-arrow:hover {
  background: #ffcf47 !important;
}

.hbquNM {
  background: #ffcf47 !important;
}

@media screen and (max-width: 870px) {
  .main-container {
    flex-direction: column-reverse;
  }

  .plane-container {
    width: 100%;
  }

  .info-container {
    width: 100%;
  }

  .seatsSelectedContainer {
    /* min-height: 100px; */
    /* padding-bottom: 30px; */
    height: 100% !important;
    padding-bottom: 0px;
    margin-top: 5px;
  }

  .seat-info {
    /* width: 8rem; */
    width: 90%;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    /* width: 0rem; */
    text-align: center;
    /* background: rgba(0, 0, 0, 0.311); */
    position: unset;
    /* bottom: 10px; */
    /* left: 6%; */
    /* left: 1%; */
    box-shadow: 0 0 3px #000;
    border-radius: 6px;
    z-index: 111;
    padding: 10px;
  }
}

@media screen and (max-width: 430px) {
  .cockpit {
    width: 320px;
  }

  .cockpit:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -20px;
    height: 500px;
    width: 100%;
    border-radius: 50%;
    border-right: 5px solid #d8d8d8;
    border-left: 5px solid #d8d8d8;
  }

  .plane {
    position: relative;
    margin: 20px auto;
    overflow-x: hidden;
    width: 290px;

    padding: 0px;
  }
}
